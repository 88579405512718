import alertify from 'alertifyjs';

const Flasher = {
  notify(msg, delay = 5) {
    alertify.set('notifier','position', 'top-right');
    alertify.notify(msg, 'notify', delay);
  },
  success(msg) {
    alertify.set('notifier','position', 'top-right');
    alertify.success(msg);
  },
  error(msg) {
    alertify.set('notifier','position', 'top-right');
    alertify.error(msg);
  },
  errors(errors) {
    alertify.set('notifier','position', 'top-right');
    for (var i = 0; i < errors.length; i++) {
      alertify.error(errors[i]);
    };
  },
  delete(origin, event, uuid) {
    const caller = origin;
    const evt = event;
    const id = uuid;

    alertify
      .okBtn("Ja ik weet het zeker")
      .cancelBtn("Nee")
      .confirm("Weet je het zeker?", () => {
        caller.$emit(evt, id);
      }, () => {
        alertify
          .logPosition("top right")
          .error("Het item is niet verwijderd.");
      });
  }
};

export default {
 install(Vue, options) {
    // 1. add global method or property
    Vue.prototype.$flasher = Flasher
  }
}
